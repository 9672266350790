import styled from "styled-components";

const commonStyle = `
  box-sizing: border-box;
  font-size: 1rem;
  background-color: #fff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
  padding: .6rem .75rem;
`;
const Input = styled.input`
  ${commonStyle}
`;

export const TextArea = styled.textarea`
  ${commonStyle}
`;
export default Input;
