export class TokenGenerationError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

export class OAuth2Error extends TokenGenerationError {}

export class CreateSessionDataError extends TokenGenerationError {}
