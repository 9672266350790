import React from "react";
import SessionTokenGenerator from "./SessionTokenGenerator";
import EmbedIframe from "./EmbedIframe";

export default function Simulador() {
  const [sessionCode, setSessionCode] = React.useState(null);

  return !sessionCode ? (
    <SessionTokenGenerator onSessionCode={setSessionCode} />
  ) : (
    <EmbedIframe sessionCode={sessionCode} />
  );
}
