export function loadJs(url) {
  return new Promise((res, rej) => {
    const script = document.createElement("script");
    script.onload = () => {
      res();
    };
    script.src = url;
    document.head.appendChild(script);
  });
}
