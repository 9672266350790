import React, { useState } from "react";
import Button from "../components/Button";
import Container from "../components/Container";
import ErrorAlert from "../components/ErrorAlert";
import FormGroup from "../components/FormGroup";
import Input, { TextArea } from "../components/Input";
import useFormState from "../components/useFormState";
import { createSessionToken } from "../services/createSessionToken";
import Code from "../components/Code";
import { DelayedLoading } from "../components/Loading";
export const initialFormValue = {
  sessionData: JSON.stringify(
    {
      cpf: "11111111111",
      valor: 100000,
      parcelas: 3,
      idBundle: "abc123",
    },
    null,
    4
  ),
  clientId: process.env.REACT_APP_SIMULADOR_CLIENT_ID || "",
  clientSecret: process.env.REACT_APP_SIMULADOR_CLIENT_SECRET || "",
  scope: "",
};
export default function AuthCodeGenerator({ onSessionCode }) {
  const { formValues, inputProps } = useFormState(initialFormValue);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const beautifyResponse = (response) => {
    if (!response) {
      return null;
    }
    return (
      <>
        {`${response.status}(${response.statusText})`} <br />
        <Code>{JSON.stringify(response.data, null, 4)}</Code>
      </>
    );
  };
  const setErrorMessage = (message) => {
    setError({ message });
  };

  const setErrorException = (exception) => {
    setError({
      message: exception.message,
      detail: beautifyResponse(exception.response),
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      let sessionData = {};
      try {
        sessionData = JSON.parse(formValues.sessionData);
      } catch {
        setErrorMessage("Error parsing sessionData");
        return;
      }
      try {
        const sessionCode = await createSessionToken(
          formValues.clientId,
          formValues.clientSecret,
          sessionData,
          formValues.scope
        );
        onSessionCode(sessionCode);
      } catch (er) {
        setErrorException(er);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <FormGroup label={"sessionData"}>
          <TextArea {...inputProps("sessionData")} rows={10} />
        </FormGroup>
        <FormGroup label={"clientId"}>
          <Input {...inputProps("clientId")} />
        </FormGroup>
        <FormGroup label={"clientSecret"}>
          <Input {...inputProps("clientSecret")} type={"password"} />
        </FormGroup>
        <FormGroup label={"scope"}>
          <Input {...inputProps("scope")} />
        </FormGroup>
        <ErrorAlert
          message={error && error.message}
          detail={error && error.detail}
        />
        {loading && <DelayedLoading />}
        <Button type={"submit"}>Iniciar</Button>
      </form>
    </Container>
  );
}
