import React from "react";
import styled from "styled-components";
import { colors } from "../global-styles";

const ErrorDiv = styled.div`
  border-radius: 5px;
  border: 1px solid ${colors.red};
  background: ${colors.redSoft};
  color: ${colors.white};
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  ${({ visible }) =>
    visible
      ? { display: "block", visibility: "visible" }
      : { display: "none", visibility: "hidden" }}

  & > h1 {
    font-size: 16px;
    margin: 0;
  }

  & > h1 + div:not(:empty) {
    padding-top: 5px;
  }
`;
ErrorDiv.displayName = "ErrorDiv";

export default function ErrorAlert({ message, detail }) {
  const visible = !!message;
  return (
    <ErrorDiv visible={visible}>
      <h1>{message}</h1>
      <div>{detail}</div>
    </ErrorDiv>
  );
}
