import styled from "styled-components";
import { colors } from "../global-styles";

const Button = styled.button`
  width: 100%;
  background-color: ${colors.green};
  border: 0;
  border-radius: 5px;
  padding: 15px 10px;
  color: ${colors.white};
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  ${({ color, background }) => ({ color, background })}
`;

export default Button;
