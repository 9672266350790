import axios from "axios";
import { CREATE_SESSION_TOKEN_URL } from "../config";
import { authenticateOAuth } from "./authenticateOAuth";
import { CreateSessionDataError } from "./TokenGenerationError";

export async function createSessionToken(
  clientId,
  clientSecret,
  sessionData,
  scope
) {
  const authorizationHeader = await authenticateOAuth(clientId, clientSecret);
  try {
    const response = await axios.post(
      CREATE_SESSION_TOKEN_URL(scope),
      sessionData,
      {
        headers: {
          Authorization: authorizationHeader,
        },
      }
    );

    return response.data["sessionToken"];
  } catch (error) {
    if (error.response) {
      throw new CreateSessionDataError(
        "Error creating session",
        error.response
      );
    }
    throw error;
  }
}
