import axios from "axios";
import { AUTHENTICATION_URL } from "../config";
import { OAuth2Error } from "./TokenGenerationError";

export async function authenticateOAuth(clientId, clientSecret) {
  try {
    const response = await axios.post(AUTHENTICATION_URL, null, {
      auth: { username: clientId, password: clientSecret },
    });

    return response.data.token_type + " " + response.data.access_token;
  } catch (error) {
    if (error.response) {
      throw new OAuth2Error(
        `Error authenticating with credentials`,
        error.response
      );
    }
    throw error;
  }
}
