import React from "react";
import styled from "styled-components";
import { defaultFontFamily } from "../global-styles";

const Label = styled.label`
  display: block;
  font-family: ${defaultFontFamily};
  color: #212121;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`;
const FormDiv = styled.div`
  position: relative;
  input {
    ${({ inputStyle }) => inputStyle}
  }
  ${({ spacing }) => (spacing ? { marginBottom: spacing } : {})}
  ${({ invalid, touched, invalidColor, validColor }) =>
    invalid
      ? `
      input{
        border: 1px solid ${invalidColor || "#dc3545"}!important;
      }
  `
      : touched && validColor
      ? `input{
        border: 1px solid ${validColor}!important;
      }
      `
      : null}
`;
FormDiv.displayName = "FormDiv";
FormDiv.defaultProps = {
  inputProps: {},
};
const ErrorMessage = styled.div`
  display: block;
  font-size: 14px;
  height: 14px;
  margin-top: 2px;
  ${({ invalidColor }) => `color:${invalidColor || "#dc3545"} `};
  ${({ visible }) => (visible ? `visibility:visible;` : `visibility:hidden;`)}
`;
const ErrorWrapper = styled.div`
  position: relative;
`;
export default function FormGroup({
  label,
  children,
  customStyle,
  errorMessage,
  invalidColor,
  validColor,
  inputStyle,
  spacing,
  touched,
}) {
  return (
    <FormDiv
      style={customStyle}
      invalid={!!errorMessage}
      invalidColor={invalidColor}
      validColor={validColor}
      touched={touched}
      inputStyle={inputStyle}
      spacing={spacing}
    >
      <Label>{label}</Label>
      <ErrorWrapper>
        {children}
        <ErrorMessage visible={!!errorMessage} invalidColor={invalidColor}>
          {errorMessage}
        </ErrorMessage>
      </ErrorWrapper>
    </FormDiv>
  );
}
