import React from "react";
import Container from "../components/Container";
import { loadJs } from "../services/loadJs";
import { DIGI_CLIENT_JS_URL } from "../config";

export default function EmbedIframe({ sessionCode }) {
  const [loadedJs, setLoadedJs] = React.useState(false);
  const divRef = React.useRef();
  React.useEffect(() => {
    Promise.all([loadJs(DIGI_CLIENT_JS_URL)]).then(() => setLoadedJs(true));
  }, []);

  React.useEffect(() => {
    if (loadedJs && divRef.current) {
      const paymentService = window.initPaymentService("", divRef.current);
      paymentService.authorize(sessionCode, (err, detail) => {
        if (err) {
          if (err.name === paymentService.ERROR_AUTHORIZATION_TYPE) {
            alert(
              "Não foi possível autorizar a compra, motivo:" + err.detail.reason
            );
            //validation-error, tokenization-error são apenas infos, a tela irá informar o erro
          } else {
            alert("Unknown error " + err.name);
          }
        } else {
          alert("Success:" + JSON.stringify(detail));
        }
      });
    }
  }, [loadedJs, divRef, sessionCode]);

  return (
    <Container>
      <div ref={divRef}></div>
    </Container>
  );
}
