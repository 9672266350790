export const CREATE_SESSION_TOKEN_URL = (scope) =>
  `${process.env.REACT_APP_DIGI_API_URL}/v1/sessions?${
    scope ? `scope=${scope}` : ""
  }`;

export const AUTHENTICATION_URL =
  process.env.REACT_APP_DIGI_API_URL +
  "/v1/auths/oauth2/token?grant_type=client_credentials";

export const DIGI_CLIENT_JS_URL = process.env.REACT_APP_DIGI_CLIENT_URL;
